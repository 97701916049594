<div class="row">
    <div class="col-6" *ngIf="properties.length > 0">
        <div class="dash-details-card">
            <div class="dash-details-card-header">
                <span>Situação patrimonial e financeira</span>
            </div>

            <app-spacer [bottom]="24"></app-spacer>

            <div>
                <app-table-list [columns]="columns" [data]="properties"
                    [footerData]="footerData">
                </app-table-list>
            </div>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>

    <div class="col-6" *ngIf="others.length > 0">
        <div class="dash-details-card">
            <div class="dash-details-card-header">
                <span>Outros Bens e Direitos</span>
            </div>

            <app-spacer [bottom]="24"></app-spacer>

            <div>
                <app-table-list [columns]="othersColumns" [data]="others"
                    [footerData]="othersFooterData">
                </app-table-list>
            </div>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>

    <div class="col-6" *ngIf="wage">
        <div class="dash-details-card">
            <div class="dash-details-card-header"></div>
            <div class="dash-details-card-body">
                <div class="row">
                    <div class="col-12 col-md-6"
                        *ngIf="isPj && wage.financial_position_at_pj">
                        <div class="d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                Data da fundação
                            </span>

                            <span class="dash-details-card-item-title">
                                {{ wage.financial_position_at_pj | date:
                                'dd/MM/yyyy' }}
                            </span>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                {{isPj ? 'Patrimônio líquido' : 'Salário/Pró
                                labore'}}
                            </span>

                            <span class="dash-details-card-item-title">
                                {{wage.wage ? formatBrl(wage.wage) : '-'}}
                            </span>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                {{isPj ? 'Faturamento médio mensal' : 'Outros
                                Rendimentos'}}
                            </span>

                            <span class="dash-details-card-item-title">
                                {{wage.other_income ?
                                formatBrl(wage.other_income) :
                                '-'}}
                            </span>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12"
        *ngIf="!wage && others.length === 0 && properties.length === 0">
        <div class="dash-details-card card-padding-top">
            <app-empty-content [isVisible]="true" title="Nenhum dado encontrado"
                description="Não foi encontrado nenhum dado referente a rendimentos e patrimônios nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>