import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, UserTypes } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';

import UIDefaultTheme from 'src/themes';
import { ToastService } from 'src/app/services/toast/toast.service';
import { MaskPipe } from 'ngx-mask';
import Utils, { UtilsValidators } from 'src/app/helpers/utils';
import DDIOptions from 'src/app/data/ddi';
import { permission } from 'src/app/data/permissions';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-dash-sidebar',
  templateUrl: './dash-sidebar.component.html',
  styleUrls: ['./dash-sidebar.component.scss'],
})
export class DashSidebarComponent implements OnInit {
  @ViewChild('toggleOptions') toggleOptions: ElementRef;
  @ViewChild('toggleSwitch') toggleSwitch: ElementRef;

  newUserForm = this.formBuilder.group({
    role: [null, [Validators.required]],
    interest_fund: [null, []],
    agent: [null, []],
  });

  roleForm = this.formBuilder.group({
    role: [null, [Validators.required]],
  });

  changeBasicInfoForm = this.formBuilder.group({
    full_name: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    // phone_number: [null, [Validators.required, UtilsValidators.telefone]],
    phone_ddi: ['55', [Validators.required]],
  });

  changePasswordForm = this.formBuilder.group({
    current_password: [null, [Validators.required, Validators.minLength(8)]],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        (control: FormControl) => {
          const password = control.value;

          if (!this.hasNumber(password)) return { noNumber: true };

          if (!this.hasLowerCase(password)) return { noLowerCase: true };

          if (!this.hasUpperCase(password)) return { noUpperCase: true };

          if (!this.hasSpecialCharacter(password))
            return { noSpecialCharacter: true };

          return null;
        },
      ],
    ],
  });

  accounts: RegisterUserResponse[] = [];

  visible_accounts: RegisterUserResponse[] = [];

  selectedRegisterName: string = 'Carregando...';

  selectedRegisterDocument: string = '';

  selectedRegister: RegisterUserResponse = null;

  selectedRegisterRoles: any[] = [];

  currentRole: string = '';

  menu: MenuItem[] = [];

  submenu: MenuItem[] = [];

  switcherOpen = false;

  openNotify: boolean = false;
  userOptionsOpen: boolean = false;

  DDIOptions = DDIOptions;

  roleModal: boolean = false;

  form = this.formBuilder.group({
    search: [null, []],
  });

  searchOpen: boolean = false;

  subject: Subject<string> = new Subject();

  passwordSubmiting: boolean = false;
  basicInfoSubmiting: boolean = false;
  changePasswordStep: number = 0;

  columns: TableColumnList<UserProps>[] = [
    {
      name: 'Papel',
      cell: (row) => row.role,
    },
    {
      name: 'Documento',
      cell: (row) => row.document,
    },
    {
      name: 'Nome',
      cell: (row) => row.name,
      size: 2,
    },
  ];

  users: UserProps[] = [];

  notifications = [];
  haveNewNotifications: boolean = false;
  userCredentials: UserCredentials = {
    email: '',
    name: '',
  };

  is_approver = false;
  isAdmin = false;
  sendingRequest = false;
  changePasswordShow = false;
  changeBasicInfoShow = false;
  searching = false;
  loadingMenu = true;
  loadingContent = true;

  newUserModal: boolean = false;
  roles: Role[] = [];
  primaryRoleOptions: SelectItem[] = [];
  fundOptions: SelectItem[] = [];
  roleOptions: SelectItem[] = [];
  filteredRoleOptions: SelectItem[] = [];

  userNacionality: string;
  userDocument: string;

  selectedApplicable: string = 'assignor';

  public UIDefaultTheme = UIDefaultTheme;

  constructor(
    private router: Router,
    private authService: AuthService,
    public signupService: SignupService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private maskPipe: MaskPipe,
    private storageService: StorageService,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.toggleOptions?.nativeElement.contains(e.target)) {
        this.userOptionsOpen = false;
      }

      if (!this.toggleSwitch?.nativeElement.contains(e.target)) {
        this.switcherOpen = false;
      }
    });
  }

  ngOnInit(): void {
    this.getData();

    this.userCredentials = {
      email: this.authService.user?.email,
      name:
        this.authService.user?.first_name +
        ' ' +
        this.authService.user?.last_name,
    };
    this.signupService.fetchDataEvent.emit();

    const user = this.authService.getUser();

    this.isAdmin = false;
    user.role &&
      user.role.applicable === 'admin' &&
      user.active_register === null;

    if (this.authService.user.active_register) {
      this.is_approver =
        this.authService.user.active_register.register.is_approver;
    }

    this.authService.userChange.subscribe((user) => {
      this.getData();
    });

    this.form.controls.search.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searching = false;
        if (value) {
          this.searchUser(value);
        } else {
          this.users = [];
        }
      });

    if (this.authService.user) {
      this.getNotifications();
      setTimeout(() => {
        this.getNotifications();
      }, 3000);
    }

    this.newUserForm.controls.role.valueChanges.subscribe((value) => {
      const selectedRole = this.roles.find((item) => item.id === Number(value));

      this.selectedApplicable = selectedRole
        ? selectedRole.applicable
        : 'assignor';

      if (this.selectedApplicable === 'provider') {
        this.newUserForm.controls.agent.setValidators([Validators.required]);
      } else {
        this.newUserForm.controls.agent.setValidators([]);
      }

      this.newUserForm.controls.agent.setValue(null);
      this.newUserForm.controls.interest_fund.setValue(null);

      this.newUserForm.controls.agent.updateValueAndValidity();
      this.newUserForm.controls.interest_fund.updateValueAndValidity();
    });
  }

  async getData() {
    const token = await this.authService.getToken();

    await this.getAccounts(), this.handleSetMenu();
    await this.changeActiveRegisterName();

    await Promise.all([this.getRoles(), this.setAgents(), this.getFunds()]);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.openNotify) {
      if (!event.target.className.includes('content-header-profile-icon')) {
        this.openNotify = false;
      }
    }
  }

  setUserBasicInfo() {
    const user = this.authService.getUser();

    this.userNacionality = user.nationality?.country ?? null;
    this.userDocument = user.documents?.number
      ? this.maskPipe.transform(user.documents.number, '000.000.000-00')
      : null;

    this.changeBasicInfoForm.patchValue({
      full_name: user.first_name + ' ' + user.last_name,
      email: user.email,
      //   phone_number: user.phone
      //     ? this.maskPipe.transform(user.phone.number, '(00) 00000-0000')
      //     : null,
      phone_ddi: user.phone ? String(user.phone.code) : '55',
    });
  }

  toggleChangePasswordModal() {
    this.changePasswordShow = !this.changePasswordShow;

    if (!this.changePasswordShow) {
      setTimeout(() => {
        this.changePasswordForm.reset();
        this.changePasswordStep = 0;
      }, 500);
    }
  }

  toggleChangeBasicInfoModal() {
    this.changeBasicInfoShow = !this.changeBasicInfoShow;

    if (!this.changeBasicInfoShow) {
      setTimeout(() => {
        this.changeBasicInfoForm.reset();
      }, 500);
    } else {
      this.setUserBasicInfo();
    }
  }

  toggleSearch() {
    this.searchOpen = true;
  }

  async getAccounts(type: string = 'profile', register_id: string = null) {
    try {
      const { data } = await this.api.get<ApiResponse<RegisterUserResponse[]>>({
        route: `api/registration/register_user/`,
        params: {
          type: type,
          register: register_id,
        },
        token: true,
      });

      this.accounts = data
        .sort((a, b) => {
          return (
            new Date(b.register.created_at).getTime() -
            new Date(a.register.created_at).getTime()
          );
        })
        .reverse();

      this.authService.accountsArr = this.accounts;

      this.visible_accounts = this.accounts.filter((a) => a.type == 'profile');
    } catch (error) {
      console.error(error);
    }

    this.loadingContent = false;
  }

  async getNotifications() {
    try {
      const res = await this.api.get({
        route: `notifications/`,
        token: true,
      });

      this.notifications = res.data;

      this.haveNewNotifications = this.notifications.some(
        (notification) => notification.read === false
      );
    } catch (error) {
      console.error(error);
    }
  }

  toggleUserOptions() {
    this.userOptionsOpen = !this.userOptionsOpen;
  }

  async handleNotifyClick(notification, idx) {
    try {
      await this.api.put({
        route: `notifications/`,
        token: true,
        params: {
          id: notification.id,
        },
      });

      this.notifications[idx].read = true;

      switch (notification.action) {
        case 'redirect':
          window.open(notification.param, '_blank');
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async markAllAsread() {
    try {
      await this.api.put({
        route: `notifications/`,
        token: true,
      });

      this.notifications.forEach((notification) => {
        notification.read = true;
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleCloseSearching() {
    this.searchOpen = false;
    this.form.controls.search.setValue(null);
  }

  handleSearch(event) {
    // if (this.users.length === 1) {
    //   this.redirectUser(0);
    // } else {
    //   this.router.navigateByUrl('/app/dashboard');
    // }

    if (this.users.length >= 1) {
      this.redirectUser(0);
    }

    this.handleCloseSearching();
  }

  async searchUser(value: any) {
    this.searching = true;

    try {
      let param = /\d/.test(value) ? Utils.onlyNumbers(value) : value;

      const res = await this.api.get<SearchResponse[]>({
        route: `api/registration/search/`,
        params: {
          search: param,
          //   return_distinct: true,
        },
        token: true,
      });

      const rolesToFilter = [];
      const roles = [
        'assignor',
        'shareholder',
        'provider',
        'representative',
        'fund',
      ];

      roles.forEach((role) => {
        const slug = `de_can_view_${role}`;
        if (!this.authService.verifyPermission(slug)) {
          rolesToFilter.push(role);
        }
      });

      const filteredData = res.filter((item) => {
        return item.role
          ? !rolesToFilter.includes(item.role)
          : !rolesToFilter.includes(item.type);
      });

      const newUsers: UserProps[] = filteredData.map((approval) => {
        const isRegister = approval.fantasy_name === null;

        return {
          role: this.formatName(isRegister ? approval.role : 'fund'),
          applicable: isRegister ? approval.role : approval.type,
          id: isRegister ? approval.id : String(approval.id),
          document: this.formatSearchedDocument(approval),

          name: this.formatSearchedName(approval),
        };

        /* switch (requested.search_type) {
          case 'fund':
            return {
              role: 'Fundo',
              applicable: 'fund',
              id: requested.id,
              document: requested.document,
              name: requested.name,
            };

          case 'user':
          default:
            const newRequested = { ...requested };
            let name =
              newRequested.user.first_name + ' ' + newRequested.user.last_name;

            if (
              newRequested.user.type === 'PJ' ||
              newRequested.user.type === 'pj'
            ) {
              name = newRequested.general.corporate_name;
            }

            return {
              role: this.formatName(newRequested.user.role.applicable),
              applicable: newRequested.user.role.applicable,
              id: newRequested.id,
              document: `${this.formatDocument(newRequested.general.document)}`,
              name: name,
            };
        } */
      });

      this.users = [...newUsers];
    } catch (error) {
      console.error(error);
    }
    this.searching = false;
  }

  formatSearchedDocument(approval: SearchResponse) {
    let document = '';

    if (approval.fantasy_name !== undefined) {
      document = this.maskPipe.transform(
        approval.document_number,
        '00.000.000/0000-00'
      );
    } else {
      document =
        approval.type === 'PF'
          ? this.maskPipe.transform(approval.document_number, '000.000.000-00')
          : this.maskPipe.transform(
              approval.document_number,
              '00.000.000/0000-00'
            );
    }

    return document;
  }

  formatSearchedName(approval: SearchResponse) {
    let name = '';

    if (approval.fantasy_name !== undefined) {
      name = approval.fantasy_name;
    } else {
      name = approval.type === 'PF' ? approval.name : approval.name;
    }

    return name;
  }

  formatName(name: string) {
    switch (name) {
      case 'assignor':
        return 'Cedente';
      case 'shareholder':
        return 'Cotista';
      case 'provider':
        return 'Prestador de serviço';
      case 'fund':
        return 'Fundo';

      default:
        return 'Representante';
    }
  }

  formatDocument(document: any) {
    if (document) {
      if (document.type === 'CNPJ' || document.type === 'cnpj') {
        return document.number.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          '$1.$2.$3/$4-$5'
        );
      } else {
        return document.number.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
          '$1.$2.$3-$4'
        );
      }
    } else {
      return '';
    }
  }

  toggleNotify() {
    this.openNotify = !this.openNotify;
  }

  handleSetMenu() {
    const user = this.authService.getUser();

    if (user) {
      if (user.is_staff) {
        this.setMenu('ADM');
      } else {
        if (user) {
          const role = user.active_register
            ? user.active_register.register.role
            : user.role;

          const type = role.slug.includes('ADM')
            ? 'ADM'
            : (user.active_register.register.role.slug.split(
                '-'
              )[1] as UserTypes);

          this.setMenu(type);
        }
      }
    }

    this.loadingMenu = false;
  }

  getName(account: RegisterUserResponse) {
    if (account.register.company) {
      return account.register.company.corporate_name;
    }

    if (account.register.person) {
      return account.register.person.full_name;
    }

    return '-';
  }

  getRole(account: RegisterUserResponse) {
    if (!account) {
      return '';
    }

    if (account.register.department) {
      return 'Colaborador - ' + account.register.department.name;
    }

    return account.register.role.applicable_display;
  }

  setMenu(type: UserTypes) {
    let newMenu = [];
    const menuItems = [
      {
        title: 'Convites',
        icon: 'fas fa-user-plus',
        route: '/app/invites',
        permission: 'de_can_view_invite',
      },
      {
        title: 'Cadastros',
        icon: 'fas fa-clipboard',
        route: '/app/registers',
        permission: 'de_can_view_register',
      },
      {
        title: 'Solicitar acesso',
        icon: 'fas fa-objects-column',
        route: '/app/request-access',
        permission: 'de_can_view_access',
      },
      {
        title: 'Fundos',
        svg: 'icon-pig',
        route: '/app/funds',
        permission: 'de_can_view_fund',
      },
      {
        title: 'Colaboradores',
        icon: 'fas fa-users',
        route: '/app/organizations',
        permission: 'de_can_view_collaborator',
      },
      {
        title: 'Configurações',
        icon: 'fas fa-cog',
        route: '/app/settings',
        permission: 'de_can_view_configuration',
      },
      {
        title: 'Minutário',
        icon: 'fas fa-file-alt',
        route: '/app/drafts',
        permission: 'de_can_view_draft_document',
      },
    ];

    if (type === 'PF' || type === 'PJ') {
      const {
        active_register: {
          register: {
            id,
            role: { applicable },
          },
        },
      } = this.authService.user;

      if (applicable === 'representative' || applicable === 'provider') {
        newMenu.push({
          title: 'Minha área',
          icon: 'fas fa-objects-column',
          route: '/app/dashboard',
        });
      }

      if (
        applicable === 'shareholder' ||
        applicable === 'assignor' ||
        applicable === 'provider'
      ) {
        newMenu.push({
          title: 'Minha conta',
          icon: 'fas fa-ballot',
          route: `/app/details/${applicable}/${id}`,
        });
      }
    }

    if (type === 'ADM') {
      newMenu = [
        {
          title: 'Minha área',
          icon: 'fas fa-objects-column',
          route: '/app/dashboard',
        },
      ];
    }

    newMenu.push({
      title: 'Meus cadastros',
      icon: 'fas fa-users',
      route: '/app/accounts',
    });

    const filteredMenu = menuItems.filter((item) => {
      return this.authService.verifyPermission(item.permission);
    });

    this.menu = [...newMenu, ...filteredMenu];
  }

  getActiveRoute(route?: string) {
    if (route) {
      return this.router.url.includes(route);
    }

    return false;
  }

  redirectUser(value) {
    const searched = this.users[value];

    this.handleCloseSearching();
    if (searched.applicable === 'fund') {
      this.router.navigateByUrl(`/app/funds/approval/${searched.id}`);
    } else {
      const url = `/app/details/${searched.applicable}/`;

      if (this.router.url.includes(url)) {
        this.authService.reloadApprovalEvent.emit();
      }

      this.router.navigateByUrl(url + searched.id);
    }
  }

  redirectTo(menuItem: MenuItem) {
    if (!menuItem.children) {
      this.router.navigate([menuItem.route]);
    }
  }

  handleMenuClick(menuItem: MenuItem, event: MouseEvent) {
    event.stopPropagation();
    if (menuItem.children) {
      this.submenu = menuItem.children;
    } else {
      this.router.navigate([menuItem.route]);
      this.submenu = [];
    }
  }

  handleSubmenuClick(menuItem: MenuItem, event: MouseEvent) {
    event.stopPropagation();
    this.router.navigate([menuItem.route]);
    this.submenu = [];
  }

  toggleSwitcher(status?: boolean) {
    if (status) {
      this.switcherOpen = status;
    } else {
      this.switcherOpen = !this.switcherOpen;
    }
  }

  logoff() {
    this.authService.clearUser();

    this.router.navigate(['/login']);
  }

  toggleNewUserModal() {
    if (this.newUserModal) {
      this.newUserForm.reset();
    }

    this.newUserModal = !this.newUserModal;
  }

  async registerNewUser() {
    this.sendingRequest = true;

    try {
      const selectedRole = this.roles.find(
        (item) => item.id === Number(this.newUserForm.value.role)
      );

      const type = selectedRole.slug.split('-')[1];

      const payload = {
        role: selectedRole.id,
        type,
        interest_fund: this.newUserForm.value.interest_fund,
        status: 'created',
      };

      if (this.newUserForm.value.agent) {
        payload['agent'] = this.newUserForm.value.agent;
      }
      const res = await this.api.post({
        route: 'api/registration/register/',
        token: true,
        body: payload,
      });

      this.toast.show('info', 'Sucesso!', 'Usuário cadastrado com sucesso');

      this.newUserForm.reset();
      this.newUserModal = false;

      this.sendingRequest = false;

      await this.redirectToNewRegister(res.data.id);
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao cadastrar usuário');
    }

    this.sendingRequest = false;
  }

  async redirectToNewRegister(id: string) {
    await this.getAccounts('register', id);

    const newRegisterUser = this.accounts.find(
      (item) => item.register.id === id
    );

    await this.changeActiveRegister(newRegisterUser.id);

    this.router.navigate([
      `/signup/${
        newRegisterUser.register.role.applicable
      }/${newRegisterUser.register.type.toLowerCase()}`,
    ]);
  }

  async changeActiveRegister(id: number) {
    try {
      await this.api.patch({
        route: `user/me/`,
        token: true,
        body: {
          active_register: id,
        },
      });

      const { data } = await this.api.get<ApiResponse<NewLoginResponse>>({
        route: 'user/me/',
        token: true,
      });

      this.authService.setUser(data);

      let permissionsArr = data.active_register.permissions_role
        ? data.active_register.permissions_role.permissions
        : [];

      if (data.active_register.register.role.applicable === 'representative') {
        const defaultPermissions: Permission[] = permission;

        permissionsArr = permissionsArr.concat(defaultPermissions);
      }

      this.authService.permissions = permissionsArr;
      this.storageService.set('permissions', permissionsArr, true);

      this.handleRedirect(id);
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro!', 'Erro ao alterar conta ativa');
    }
  }

  handleRedirect(id: number) {
    const account = this.accounts.find((item) => item.id === id);

    const isApprover = account.register.is_approver;
    const applicable = account.register.role.applicable;
    const approvals = account.register.approval;
    const _id = account.register.id;
    const type = account.register?.type?.toLowerCase() || '';

    if (applicable === 'representative' || applicable === 'admin') {
      this.router.navigate(['/app/dashboard']);
      return;
    }

    if (approvals.length > 0) {
      if (isApprover) {
        this.router.navigate(['/app/dashboard']);
      } else {
        this.router.navigate([`/app/details/${applicable}/`, _id]);
      }
    } else {
      this.router.navigate([`/signup/${applicable}/${type}`]);
    }
  }

  changeActiveRegisterName() {
    const user = this.authService.getUser();

    if (user) {
      const activeRegister = this.accounts.find(
        (item) => item.id === user.active_register?.id
      );

      if (activeRegister?.register?.role?.applicable === 'provider') {
        this.selectedRegisterRoles = activeRegister?.register?.agent.map(
          (item) => {
            return {
              label: item.name,
              value: String(item.id),
            };
          }
        );
      }

      this.selectedRegister = activeRegister;
      this.currentRole = this.getRole(activeRegister);

      if (activeRegister?.register?.company) {
        this.selectedRegisterName =
          activeRegister.register.company.corporate_name;
        this.selectedRegisterDocument = this.maskPipe.transform(
          activeRegister.register.company.document.number,
          '00.000.000/0000-00'
        );
        return;
      }

      if (activeRegister?.register?.person) {
        this.selectedRegisterName =
          (activeRegister.register.person &&
            activeRegister.register.person.full_name) ??
          '';

        this.selectedRegisterDocument = this.maskPipe.transform(
          activeRegister.register.person.document?.number,
          '000.000.000-00'
        );

        return;
      }

      if (user.role && user.role.applicable === 'admin') {
        this.selectedRegisterName = 'Administrador';

        return;
      }
    }
  }

  async getRoles() {
    const res = await this.api.get<ApiResponse<Role[]>>({
      route: 'role/',
    });

    const { data } = res;

    this.roles = data;

    const userRoles = data
      .filter(
        (_item) =>
          _item.applicable === 'assignor' ||
          _item.applicable === 'provider' ||
          _item.applicable === 'shareholder'
      )
      .sort((a, b) => {
        if (a.applicable_display < b.applicable_display) {
          return -1;
        }
        if (a.applicable_display > b.applicable_display) {
          return 1;
        }
        return 0;
      })
      .map((item) => {
        return {
          label: `${item.applicable_display} - ${item.name}`,
          value: String(item.id),
        };
      });

    this.primaryRoleOptions = userRoles;
  }

  async setAgents() {
    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.roleOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/fund',
        token: true,
      });

      const filteredArr = Utils.filterRepeatedValues(res.funds, 'id');

      const funds = filteredArr.map((fund: any) => {
        return {
          label: fund.name,
          value: fund.id,
        };
      });

      this.fundOptions = funds;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar fundos de interesse');
    }
  }

  getUserInitials(string) {
    return string
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  getSelectedAccountInitials(label) {
    return label
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  getAccountInitials(item: RegisterUserResponse) {
    if (item.register.role.applicable === 'admin' && item.register.department) {
      const full_name = item.register.person.full_name;

      return full_name
        .toUpperCase()
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .slice(0, 2)
        .map((word: string) => word.charAt(0))
        .join('');
    }

    if (
      item.register.approval.length == 0 &&
      item.register.role.applicable !== 'representative'
    ) {
      return '-';
    }

    if (item.register.type.toUpperCase() === 'PF') {
      const full_name = item.register.person.full_name;

      return full_name
        .toUpperCase()
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .slice(0, 2)
        .map((word: string) => word.charAt(0))
        .join('');
    }

    if (item.register.type.toUpperCase() === 'PJ') {
      const corporate_name = item.register.company.corporate_name;

      return corporate_name
        .toUpperCase()
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .slice(0, 2)
        .map((word: string) => word.charAt(0))
        .join('');
    }

    return '';
  }

  hasNumber(str: string) {
    return /\d/.test(str);
  }

  hasLowerCase(str: string) {
    return str.toUpperCase() !== str;
  }

  hasUpperCase(str: string) {
    return str.toLowerCase() !== str;
  }

  hasSpecialCharacter(str: string) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
  }

  passwordStrength(): number[] {
    const password = this.changePasswordForm.get('password')?.value ?? '';

    const strengthSteps = [];

    if (password !== '') strengthSteps.push(0);
    if (password.length >= 8) strengthSteps.push(1);
    if (this.hasNumber(password)) strengthSteps.push(2);
    if (this.hasLowerCase(password) && this.hasUpperCase(password))
      strengthSteps.push(3);
    if (this.hasSpecialCharacter(password)) strengthSteps.push(4);

    return strengthSteps;
  }

  passwordStrengthPercentage(): number {
    return (100 * this.passwordStrength().length) / 5;
  }

  passwordStrengthText(): string {
    const percentage = this.passwordStrengthPercentage();

    if (percentage > 60 && percentage < 90) {
      return 'Sua senha precisa melhorar';
    } else if (percentage >= 90) {
      return 'Sua senha parece bem segura';
    }

    return 'Sua senha está fraca';
  }

  handleSendPassword() {
    if (this.changePasswordStep === 0) {
      this.validatePassword();
    }

    if (this.changePasswordStep === 1) {
      this.changePassword();
    }
  }

  async validatePassword() {
    this.passwordSubmiting = true;

    const password = this.changePasswordForm.get('current_password').value;
    const username = this.authService.user.documents.number;

    try {
      await this.api.post<ApiResponse<NewLoginResponse>>({
        route: 'new-login/',
        body: { username, password },
      });

      this.changePasswordStep = 1;
    } catch (error: any) {
      console.warn(error);

      this.toast.show('error', 'Erro', 'A senha atual está incorreta');

      this.changePasswordForm.controls.current_password.setErrors({
        incorrect: true,
      });
    }

    this.passwordSubmiting = false;
  }

  async changePassword() {
    this.passwordSubmiting = true;

    const current_password =
      this.changePasswordForm.get('current_password').value;
    const password = this.changePasswordForm.get('password').value;

    try {
      await this.api.patch({
        route: 'new-password/me/',
        body: {
          password,
          confirm_password: password,
          current_password,
        },
      });

      this.toast.show('info', 'Sucesso!', 'Senha alterada com sucesso');
      this.toggleChangePasswordModal();
    } catch (error: any) {
      console.warn(error);

      this.toast.show(
        'error',
        'Erro',
        'Não foi possível alterar a senha, tente novamente'
      );
    }
    this.passwordSubmiting = false;
  }

  handleDisable() {
    if (this.changePasswordStep === 0) {
      return (
        this.changePasswordForm.get('current_password').invalid ||
        this.passwordSubmiting
      );
    } else {
      return (
        this.changePasswordForm.get('password').invalid ||
        this.passwordSubmiting
      );
    }
  }

  handleBasicInfoDisable() {
    return this.changeBasicInfoForm.invalid || this.basicInfoSubmiting;
  }

  async changeBasicInfo() {
    try {
      const {
        email,
        // phone_number,
        full_name,
        phone_ddi,
      } = this.changeBasicInfoForm.value;

      const first_name = full_name.split(' ')[0];
      const last_name = full_name.split(' ').slice(1).join(' ');

      await this.api.patch({
        route: `user/me/`,
        body: {
          email,
          //   phone: {
          //     code: Number(phone_ddi),
          //     number: Utils.onlyNumbers(phone_number),
          //   },
          first_name,
          last_name,
        },
      });

      this.toast.show(
        'info',
        'Sucesso!',
        'Informações básicas alteradas com sucesso'
      );

      this.userCredentials = {
        email: email,
        name: full_name,
      };

      const user = {
        ...this.authService.user,
        email,
        first_name,
        last_name,
        // phone: {
        //   ...this.authService.user.phone,
        //   code: Number(phone_ddi),
        //   number: Utils.removeSpecialChars(phone_number),
        // },
      };

      this.authService.setUser(user);
      this.toggleChangeBasicInfoModal();
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao alterar informações básicas');
    }
  }

  toggleRoleModal() {
    this.roleModal = !this.roleModal;

    if (this.roleModal) {
      const newRoleOptions = this.roleOptions.filter((item) => {
        return (
          this.selectedRegisterRoles.findIndex(
            (agent) => Number(agent.value) === item.value
          ) === -1
        );
      });

      this.filteredRoleOptions = newRoleOptions;
    }

    setTimeout(() => {
      this.roleForm.reset();
    }, 500);
  }

  renderRoles() {
    return this.selectedRegisterRoles.map((item) => item.label).join(', ');
  }

  async addAgentRole() {
    try {
      const payload = [
        ...this.selectedRegisterRoles.map((item) => Number(item.value)),
        ...this.roleForm.controls.role.value,
      ];

      await this.api.patch<ApiResponse<Register>>({
        route: `api/registration/register/${this.selectedRegister.register.id}/`,
        token: true,
        body: {
          agent: payload,
        },
      });

      this.toast.show('info', 'Sucesso!', 'Papel adicionado com sucesso');

      const roles = this.roleForm.controls.role.value.map((item) => {
        return {
          label: this.roleOptions.find((role) => role.value === item).label,
          value: String(item),
        };
      });

      this.selectedRegisterRoles = [...this.selectedRegisterRoles, ...roles];

      this.filteredRoleOptions = this.filteredRoleOptions.filter(
        (item) => !this.roleForm.controls.role.value.includes(item.value)
      );

      this.toggleRoleModal();
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao adicionar papel');
    }
  }
}
