import { Component, Input, OnInit } from '@angular/core';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-dashboard-approval-professional',
  templateUrl: './dashboard-approval-professional.component.html',
  styleUrls: ['./dashboard-approval-professional.component.scss'],
})
export class DashboardApprovalProfessionalComponent implements OnInit {
  @Input() approval: any;

  data: ProfessionalResponse;

  constructor(private maskPipe: MaskPipe) {}

  ngOnInit(): void {
    this.data = this.approval.register.professional;
  }

  formatCnpj(value: string) {
    const newValue = this.maskPipe.transform(value, '00.000.000/0000-00');

    return newValue;
  }

  formatPhone(phone) {
    return `+${String(phone.code)} ${this.maskPipe.transform(
      phone.number,
      '(00) 00000-0000'
    )}`;
  }
}
