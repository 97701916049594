import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent implements OnInit {
  @Output() filesChange = new EventEmitter<FilePicked[]>();

  @Input() clearAttachments?: boolean = false;

  @Input() description: string =
    'O arquivo deve estar no formato PDF, JPG, JPEG, PNG, XLS ou XLSX. (1MB)';

  _files: FilePicked[] = [];

  set files(files: FilePicked[]) {
    this._files = files;
    this.filesChange.emit(this._files);
  }

  get files() {
    return this._files;
  }

  maxFileSize = 1024 * 1024 * 50;

  constructor(private toast: ToastService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.clearAttachments) {
      this.onRemove(this.files[0], new MouseEvent('click'));
    }
  }

  getFileUrl(file: File, callback: (url: string) => void) {
    const fileReader = new FileReader();

    fileReader.onload = (e: any) => {
      callback(e.target.result);
    };

    fileReader.readAsDataURL(file);
  }

  onSelect(event: any) {
    event.addedFiles.forEach((file: File) => {
      if (file.name.includes('.pdf')) {
        const newFiles = [
          ...this.files,
          { file, url: 'assets/images/pdf.png' },
        ];
        this.files = newFiles;
      } else if (file.name.includes('.xls') || file.name.includes('.xlsx')) {
        const newFiles = [
          ...this.files,
          { file, url: 'assets/images/xls.png' },
        ];
        this.files = newFiles;
      } else {
        this.getFileUrl(file, (url) => {
          const newFiles = [...this.files, { file, url }];

          this.files = newFiles;
        });
      }
    });

    if (event.rejectedFiles.length > 0) {
      const reason =
        event.rejectedFiles[0].reason === 'size' ? 'tamanho' : 'tipo';

      this.toast.show('error', 'Erro', `${reason} do arquivo inválido.`);
    }
  }

  onRemove(file: FilePicked, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (file) {
      const newFiles = [...this.files];

      newFiles.splice(this.files.indexOf(file), 1);

      this.files = newFiles;
    }
  }
}
