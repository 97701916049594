
const gitInfo = {
  branch: "master",
  commit: "2fbaf24",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/2fbaf2499aee5965fdb7407a22dfb9654c53c49a",
  date: "16/09/2024",
};

export default gitInfo;
  