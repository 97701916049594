<div class="dashboard" [class.active]="submenu.length> 0">
    <aside class="sidebar-container" [class.active]="searching"
        [class.no-action]="loadingMenu" (click)="handleCloseSearching()">

        <div class="sidebar-body">
            <ng-container *ngIf="loadingMenu">
                <ngx-skeleton-loader [theme]="{
                    width: '56px',
                    height: '63px'
                  }">
                </ngx-skeleton-loader>
            </ng-container>

            <img *ngIf="!loadingMenu" class="sidebar-logo"
                [src]="UIDefaultTheme.brand.logo">

            <app-spacer [bottom]="66"></app-spacer>

            <div class="sidebar-menu">

                <ng-container *ngIf="loadingMenu">
                    <ngx-skeleton-loader count="4" [theme]="{
                        width: '48px',
                        height: '48px',
                        marginBottom: '28px'
                      }">
                    </ngx-skeleton-loader>
                </ng-container>


                <ng-container *ngIf="!loadingMenu">
                    <div *ngFor="let item of menu; let i= index"
                        class="sidebar-menu-button-each">

                        <a class="sidebar-menu-button"
                            (click)="redirectTo(item)"
                            [class.active]="getActiveRoute(item.route)"
                            (click)="handleMenuClick(item, $event)"
                            data-toggle="tooltip" data-placement="right"
                            [title]="item.title">
                            <i [class]="item.icon"
                                *ngIf="item.icon;else svg"></i>

                            <ng-template #svg>
                                <svg-icon
                                    [src]="'assets/images/' + item.svg + '.svg'"></svg-icon>
                            </ng-template>

                            <div class="sidebar-menu-label">
                                <app-spacer [right]="8"></app-spacer>
                                <span>{{item.title}}</span>
                            </div>

                        </a>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="sidebar-menu-button-each"
                        *ngIf="selectedRegister?.register?.role?.applicable === 'provider'">
                        <a class="sidebar-menu-button" data-toggle="tooltip"
                            (click)="toggleRoleModal()" data-placement="right">
                            <i class="fas fa-ballot"></i>

                            <div class="sidebar-menu-label">
                                <app-spacer [right]="8"></app-spacer>
                                <span>Adicionar papel</span>
                            </div>
                        </a>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="sidebar-footer">
            <!--            <button class="sidebar-menu-button" (click)="logoff()"-->
            <!--                data-toggle="tooltip" data-placement="right" title="Sair">-->
            <!--                <i class="fas fa-door-open"></i>-->
            <!--            </button>-->
        </div>
    </aside>

    <aside class="sidebar-submenu" [class.active]="submenu.length> 0">
        <div *ngFor="let item of submenu; let i= index">
            <a class="sidebar-submenu-button" (click)="redirectTo(item)"
                [class.active]="getActiveRoute(item.route)"
                (click)="handleSubmenuClick(item, $event)">
                <span>{{item.title}}</span>
            </a>
        </div>
    </aside>

    <div class="content">
        <header class="content-header">
            <div class="content-header-account" *ngIf="!searchOpen">

                <div *ngIf="loadingContent" class="d-flex">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                                        width: '48px',
                                        height: '48px',
                                        margin:'0'
                                      }">
                    </ngx-skeleton-loader>

                    <app-spacer [right]="23"></app-spacer>

                    <div class="w-100 d-flex flex-column">
                        <ngx-skeleton-loader [theme]="{
                            width: '110px',
                            margin:'0 0 4px'
                          }"></ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{
                            width: '150px'
                          }"></ngx-skeleton-loader>
                    </div>
                </div>


                <div *ngIf="!loadingContent"
                    class="content-header-account-container"
                    (click)="toggleSwitcher()" #toggleSwitch>
                    <div class="dash-card-item-initials">
                        <span>{{getSelectedAccountInitials(selectedRegisterName)}}</span>
                    </div>

                    <app-spacer [right]="23"></app-spacer>

                    <div class="content-header-account-info">
                        <span class="content-header-account-indicator">
                            Você está em
                        </span>
                        <app-spacer [bottom]="4"></app-spacer>

                        <div class="content-header-account-name">
                            <span>{{selectedRegisterName}}</span>
                            <app-spacer [right]="12"></app-spacer>
                            <i class="fas fa-caret-down"></i>
                        </div>
                        <span class="content-header-account-indicator">
                            {{currentRole}}
                        </span>
                        <!-- <span class="content-header-account-indicator">
                            {{selectedRegisterDocument}}
                        </span> -->

                    </div>

                    <div class="switcher" [class.active]="switcherOpen">
                        <div class="switcher-item"
                            *ngFor="let account of visible_accounts"
                            (click)="changeActiveRegister(account.id)">
                            <div class="dash-card-item-initials">
                                <span>{{getAccountInitials(account)}}</span>
                            </div>
                            <app-spacer [right]="16"></app-spacer>

                            <div class="switcher-item-info">
                                <span
                                    class="switcher-item-name">{{getName(account)}}</span>
                                <app-spacer [bottom]="4"></app-spacer>

                                <span class="switcher-item-type">
                                    {{getRole(account)}}
                                    <ng-container
                                        *ngIf="account.register.approval.length === 0 && account.register.role.applicable !== 'representative' && account.register.role.applicable !== 'admin'">
                                        <span class="point">•</span>
                                        <span>Cadastro não finalizado</span>
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                        <div class="switcher-item">
                            <button class="switcher-item-button"
                                (click)="toggleNewUserModal()">
                                <i class="fas fa-plus"></i>
                                Criar novo cadastro
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <form class="search-button-container" [formGroup]="form"
                [class.active]="searchOpen">

                <ngx-skeleton-loader *ngIf="loadingContent" [theme]="{
                    height: '43px'
                }">
                </ngx-skeleton-loader>

                <app-search placeholder="Buscar..." *ngIf="!loadingContent"
                    [group]="form" controlName="search" [columns]="columns"
                    [items]="users" [isActive]="searchOpen"
                    [isSearching]="searching" (onClick)="toggleSearch()"
                    (changeValue)="redirectUser($event)"
                    (keydown.enter)="handleSearch($event)">
                </app-search>
            </form>

            <!-- <div class="content-header-search">
        <i class="content-header-search-icon fas fa-magnifying-glass"></i>
        <app-spacer [right]="20"></app-spacer>

        <input class="content-header-search-input" placeholder="Buscar...">
      </div> -->
            <div class="content-header-profile" *ngIf="!searchOpen">
                <!-- <button class="content-header-profile-button">
          <i class="content-header-profile-icon fas fa-magnifying-glass"></i>
        </button>
        <app-spacer [right]="16"></app-spacer>

        <button class="content-header-profile-button">
          <i class="content-header-profile-icon fas fa-grid"></i>
        </button>
        <app-spacer [right]="16"></app-spacer> -->

                <div *ngIf="loadingContent" class="d-flex align-items-center">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                        width: '24px',
                        height: '24px',
                        margin:'0 16px 0 0'
                      }">
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader appearance="circle" [theme]="{
                                width: '32px',
                                height: '32px',
                                margin:'0 16px 0 0'
                              }">
                    </ngx-skeleton-loader>

                    <div class="w-100 d-flex flex-column">
                        <ngx-skeleton-loader [theme]="{
                    width: '110px',
                    height: '16px',
                    margin:'0 0 4px'
                  }"></ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{
                    width: '150px',
                    height: '18px',
                    margin:'0'
                  }"></ngx-skeleton-loader>
                    </div>
                </div>

                <ng-container *ngIf="!loadingContent">
                    <div class="content-header-profile-notifications">
                        <button class="content-header-profile-button"
                            [class.has-notification]="haveNewNotifications"
                            (click)="toggleNotify()">
                            <i
                                class="content-header-profile-icon fas fa-bell"></i>
                        </button>

                        <div class="content-header-profile-notifications-sheet"
                            [class.active]="openNotify">

                            <div
                                class="content-header-profile-notifications-sheet-header">
                                <button (click)="markAllAsread()">
                                    Marcar todos como lido
                                </button>
                            </div>

                            <div class="content-header-profile-notifications-sheet-body
              styled-scroll">
                                <div class="content-header-profile-notifications-sheet-notification"
                                    *ngFor="let notification of notifications; let i= index"
                                    (click)="handleNotifyClick(notification, i)"
                                    [class.read]="notification.read">
                                    <div
                                        class="content-header-profile-notifications-sheet-notification-icon">
                                        <i class="fas fa-bell"></i>
                                    </div>

                                    <div
                                        class="content-header-profile-notifications-sheet-notification-content">
                                        <span
                                            class="content-header-profile-notifications-sheet-notification-content-title">{{notification.message}}</span>
                                        <span
                                            class="content-header-profile-notifications-sheet-notification-content-subtitle"
                                            *ngIf="notification.created_at">{{notification.created_at
                                            |
                                            date:'dd/MM/yyyy HH:mm'}}</span>
                                    </div>
                                </div>

                            </div>

                            <!-- <div class="content-header-profile-notifications-sheet-footer">
              <button>Ver todos</button>
            </div> -->
                        </div>
                    </div>
                    <app-spacer [right]="16"></app-spacer>

                    <div class="credentials-container" #toggleOptions
                        (click)="toggleUserOptions()">

                        <div class="dash-card-item-initials user-credentials">
                            <span>{{getUserInitials(userCredentials.name)}}</span>
                        </div>

                        <div>
                            <h3>{{userCredentials.name}}</h3>
                            <p>{{userCredentials.email}}</p>
                        </div>
                        <div>
                            <i class="fas fa-caret-down"></i>
                        </div>

                        <div class="credentials-options"
                            [class.active]="userOptionsOpen">
                            <div class="credentials-options-item"
                                (click)="toggleChangeBasicInfoModal()">
                                Editar meus dados
                            </div>
                            <div class="credentials-options-item"
                                (click)="toggleChangePasswordModal()">
                                Alterar minha senha
                            </div>
                            <div class="credentials-options-item"
                                (click)="logoff()">
                                Sair
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </header>

        <div class="gray-box" [class.active]="searchOpen"
            (click)="handleCloseSearching()">
            <div class="content-body">
                <app-container [noBg]="true">
                    <router-outlet></router-outlet>
                </app-container>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="roleModal" title="Adicionar papel"
    (onClose)="toggleRoleModal()">
    <form [formGroup]="roleForm">
        <h3 class="title">Papéis atuais</h3>
        <p class="sub-title">{{renderRoles()}}</p>
        <hr>

        <div class="row">
            <div class="col-12">
                <app-select [items]="filteredRoleOptions" [multiple]="true"
                    [group]="roleForm" controlName="role"
                    placeholder="Selecione" label="Papel">
                </app-select>
                <app-spacer [bottom]="32"></app-spacer>
            </div>
        </div>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="toggleRoleModal()" [phantom]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="addAgentRole()"
                        [disabled]="roleForm.invalid" class="add-button"
                        [loading]="sendingRequest">
                        Adicionar
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-side-modal [active]="newUserModal" title="Novo cadastro"
    (onClose)="toggleNewUserModal()">
    <form [formGroup]="newUserForm">
        <div class="row">
            <div class="col-12">
                <app-select [items]="primaryRoleOptions" [group]="newUserForm"
                    controlName="role" placeholder="Selecione" label="Papel">
                </app-select>
                <app-spacer [bottom]="32"></app-spacer>
            </div>

            <ng-container *ngIf="newUserForm.controls.role.value">
                <div class="col-12" *ngIf="selectedApplicable === 'provider'">
                    <app-select [items]="roleOptions" [group]="newUserForm"
                        controlName="agent" [multiple]="true"
                        placeholder="Selecione" label="Agente">
                    </app-select>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>

                <div class="col-12">
                    <app-select [items]="fundOptions" [group]="newUserForm"
                        controlName="interest_fund" placeholder="Selecione"
                        label="Fundo de interesse">
                    </app-select>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </ng-container>
        </div>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="toggleNewUserModal()" [phantom]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="registerNewUser()"
                        [disabled]="newUserForm.invalid" class="add-button"
                        [loading]="sendingRequest">
                        Cadastrar
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-user-settings-modal (onClose)="toggleChangePasswordModal()"
    [active]="changePasswordShow" title="Alterar minha senha"
    [confirmLabel]="changePasswordStep === 0 ? 'Validar senha' : 'Alterar senha'"
    [isDisabled]="handleDisable()" [isLoading]="passwordSubmiting"
    (onSubmit)="handleSendPassword()"
    figure="assets/images/figure-change-password.svg">
    <form [formGroup]="newUserForm">
        <ng-container *ngIf="changePasswordStep === 0">
            <app-input [group]="changePasswordForm"
                controlName="current_password" type="password"
                label="Infome a senha atual">
            </app-input>
        </ng-container>

        <ng-container *ngIf="changePasswordStep === 1">
            <div class="row">
                <div class="col-12">
                    <app-radio [readOnly]="true"
                        [isChecked]="passwordStrength().includes(1)">Mínimo
                        de 8 caracteres
                    </app-radio>
                    <app-spacer [bottom]="14"></app-spacer>
                </div>
                <div class="col-12">
                    <app-radio [readOnly]="true"
                        [isChecked]="passwordStrength().includes(2)">Mínimo
                        de 1 número
                    </app-radio>
                    <app-spacer [bottom]="14"></app-spacer>
                </div>
                <div class="col-12">
                    <app-radio [readOnly]="true"
                        [isChecked]="passwordStrength().includes(3)">Letras
                        maiúsculas e
                        minúsculas</app-radio>
                    <app-spacer [bottom]="14"></app-spacer>
                </div>
                <div class="col-12">
                    <app-radio [readOnly]="true"
                        [isChecked]="passwordStrength().includes(4)">Caracteres
                        especiais
                    </app-radio>
                    <app-spacer [bottom]="21"></app-spacer>
                </div>

                <div class="col-12">
                    <p class="user-settings-modal-description-text">Para
                        reforçar a
                        segurança da sua senha, não utilize
                        informações
                        pessoais como datas de nascimentos ou números de
                        telefones e nem números sequenciais.</p>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-input type="password"
                        label="Defina uma senha de acesso"
                        [group]="changePasswordForm" controlName="password"
                        placeholder="Digite sua senha">
                    </app-input>
                    <div class="user-settings-modal-password-meter">
                        <p class="user-settings-modal-password-meter-text">
                            {{passwordStrengthText()}}</p>

                        <div class="user-settings-modal-password-meter-bars"
                            [class.strong]="passwordStrengthPercentage() > 90">
                            <div class="user-settings-modal-password-meter-bar"
                                [class.weak]="passwordStrengthPercentage() > 30"
                                [class.middle]="passwordStrengthPercentage() > 60">
                            </div>
                            <div class="user-settings-modal-password-meter-bar"
                                [class.middle]="passwordStrengthPercentage() > 60">
                            </div>
                            <div class="user-settings-modal-password-meter-bar">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
</app-user-settings-modal>

<app-user-settings-modal (onClose)="toggleChangeBasicInfoModal()"
    [active]="changeBasicInfoShow" title="Editar meus dados"
    confirmLabel="Salvar alterações" [isDisabled]="handleBasicInfoDisable()"
    [isLoading]="basicInfoSubmiting" (onSubmit)="changeBasicInfo()"
    figure="assets/images/ilustra_perfil_Prancheta.svg">
    <form [formGroup]="changeBasicInfoForm">
        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    nacionalidade
                </span>

                <span class="dash-details-card-item-title">
                    {{userNacionality}}
                </span>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    cpf
                </span>

                <span class="dash-details-card-item-title">
                    {{userDocument}}
                </span>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Nome completo" [group]="changeBasicInfoForm"
                    controlName="full_name"
                    helpText="Sem abreviações, como está em seus documentos"
                    placeholder="John Doe" [maxLength]="60">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Seu melhor e-mail"
                    [group]="changeBasicInfoForm" controlName="email"
                    placeholder="john.doe@mail.com">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <!-- <div class="col-4 col-lg-3">
                <app-select label="DDI" [group]="changeBasicInfoForm"
                    controlName="phone_ddi" [items]="DDIOptions"
                    placeholder="55">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div> -->

            <!-- <div class="col-8 col-lg-5">
                <app-masked-input label="Número do seu celular"
                    [group]="changeBasicInfoForm" controlName="phone_number"
                    mask="(00) 00000-0000||(00) 0000-0000"
                    placeholder="(00) 99887-7665">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div> -->
        </div>
    </form>
</app-user-settings-modal>